import { useCallback } from "react";

import { useRootLayoutData } from "~/root";

import type { Image } from "../lib/app.types";

const MEDIA = {
  plp: ["$plp_pwa_desktop$", ""] as const,
  pdp: ["$pdp_desktop_pwa$", ""] as const,
  thumb: ["$pdp_thumb$", ""] as const,
} as const;

const isMedia = (type: string): type is keyof typeof MEDIA => {
  return type in MEDIA;
};

const useImageUtils = () => {
  const { IMAGES_HOST } = useRootLayoutData();
  const baseUrl = IMAGES_HOST || "";

  const getImageLink = useCallback(
    (imageLink: string) => {
      if (!imageLink) return "";
      return imageLink.charAt(0) === "/" ? `${baseUrl}${imageLink}` : imageLink;
    },
    [baseUrl],
  );

  const getImageMediaLink = useCallback(
    (
      image: Image,
      {
        type,
        quality,
        params,
      }: {
        type?: keyof typeof MEDIA;
        quality?: number;
        params?: Record<string, string>;
      },
    ): string => {
      const url = new URL(getImageLink(image.disBaseLink || image.link));
      let searchParams = url.searchParams;

      if (type && isMedia(type) && MEDIA[type]) {
        url.searchParams.set(MEDIA[type][0], MEDIA[type][1]);
      }
      if (quality) url.searchParams.set("qlt", quality.toString());
      if (params) {
        searchParams = new URLSearchParams({
          ...Object.fromEntries(searchParams),
          ...params,
        });
      }

      return `${url.origin}${url.pathname}?${searchParams.toString()}`;
    },
    [getImageLink],
  );
  return {
    getImageLink,
    getImageMediaLink,
  };
};

export default useImageUtils;
