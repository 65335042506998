import { useMemo } from "react";

import { useMatches } from "@remix-run/react";

export type Analytics = {
  action: string;
  category: string;
  label: string;
  value: string;
  [key: string]: string;
};
const isAnalytics = (data: unknown): data is Analytics => {
  return (
    data !== null &&
    typeof data === "object" &&
    "action" in data &&
    "category" in data &&
    "label" in data &&
    "value" in data
  );
};
export function usePageAnalytics() {
  const matches = useMatches();

  const analyticsFromMatches = useMemo((): Analytics | undefined => {
    let data: Analytics | undefined = undefined;

    matches.forEach(event => {
      const eventData = event?.data;

      if (
        eventData &&
        typeof eventData === "object" &&
        "analytics" in eventData
      ) {
        const analytics = eventData["analytics"];
        if (!isAnalytics(analytics)) {
          console.warn("Invalid analytics data", analytics);
          return;
        }
        data = Object.assign(data || {}, analytics);
      }
    });

    return data;
  }, [matches]);

  return analyticsFromMatches;
}
