import { useEffect, useRef } from "react";
import { Toaster } from "react-hot-toast";
import { IntlProvider } from "react-intl";

import type { ShouldRevalidateFunction } from "@remix-run/react";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useRouteLoaderData,
  useSearchParams,
} from "@remix-run/react";
import type { LinksFunction } from "@remix-run/server-runtime";
import { json } from "@remix-run/server-runtime";

import { fetchTranslationsFromKv } from "./lib/utils-server/locale.server";
import { getContext } from "./server/context.server";
import { getSessionServerContext } from "./server/sessions.server";

import { ContentfulLivePreviewProvider } from "@contentful/live-preview/react";

import { SiteProvider } from "./contexts";
import { GA4 } from "./google-analytics";
import { DEFAULT_COUNTRY_CODES } from "./lib/constants";
import { getCountryByCode, getCountryByLocale } from "./lib/countries";
import { ClientOnly } from "./lib/ui";
import { ClientHintCheck, getHints } from "./lib/utils/client-hints";
import { createUrlTemplate, getDomainUrl } from "./lib/utils/url";
import { invariant } from "./lib/utils/utils";
import { DeferH1Seo, DeferSeo } from "./seo/seo";
import "./styles/app.css";
import "./styles/custom-fonts.css";
import "./styles/radix-themes/colors-light.css";
import "./styles/radix-themes/radius.css";
import "./styles/radix-themes/scaling.css";
import "./styles/radix-themes/storefront.css";
import "./styles/radix-themes/theme.css";
import "./styles/swiper.css";

export { RootErrorBoundary as ErrorBoundary } from "~/components/error-boundries/root-error-boundary";

export const loader = async ({ request, context }: LoaderArgs) => {
  const { locale, currency, site, lang } = context;
  const translations = await fetchTranslationsFromKv(lang);
  const preferredCountryCode = context.session.get("preferredCountryCode");
  const { env } = getSessionServerContext();
  const IMAGES_HOST = env?.IMAGES_HOST;
  let preferredCountry =
    preferredCountryCode && getCountryByCode(preferredCountryCode);
  if ((preferredCountry && preferredCountry.locale) !== locale.id) {
    preferredCountry = getCountryByLocale(locale.id);
  }

  const requestCountryCode = getContext().request.cf?.isEUCountry
    ? DEFAULT_COUNTRY_CODES.GB
    : DEFAULT_COUNTRY_CODES.US;
  //TODO: Redirect if request is not on the preferred site
  // const [preferredLocale, Site] = (preferredCountryCode &&
  //   getLocaleByCountry(preferredCountryCode)) || [undefined, undefined];
  return json({
    locale,
    currency,
    site,
    lang,
    translations,
    APP_DEFAULT_LANG,
    IMAGES_HOST,
    requestInfo: {
      ismobile: context.ismobile,
      requestCountryCode,
      isEUCountry: getContext().request.cf?.isEUCountry,
      hints: getHints(request),
      origin: getDomainUrl(request),
      /** *preferred country from session* or request country if not set */
      preferredCountry:
        preferredCountry || getCountryByCode(requestCountryCode),
      /** actual request country */
      country: requestCountryCode
        ? getCountryByCode(requestCountryCode)
        : undefined,
    },
  });
};

export const shouldRevalidate: ShouldRevalidateFunction = ({
  formData,
  currentParams: { locale: currentLocale },
  nextParams: { locale: nextLocale },
}) => {
  if (["setCountry"].includes(formData?.get("action")?.toString() || "")) {
    return true;
  }
  return currentLocale !== nextLocale;
};

export const links: LinksFunction = () => {
  return [];
};

export const useRootLayoutData = () => {
  const data = useRouteLoaderData<typeof loader>("root");
  invariant(data, "useRootLayoutData must be used within root layout");
  return data;
};

export default function App() {
  // Get the locale from the loader
  const { lang } = useLoaderData<typeof loader>();
  const [searchParams] = useSearchParams();
  const preview = !!searchParams.get("preview");
  return (
    <ContentfulLivePreviewProvider
      // currently our contentful settings support only "en-US" translation, so in order for the preview functionality to work, we need to pass it as a value for the locale below
      locale={"en-US"}
      enableInspectorMode={preview}
      enableLiveUpdates={preview}
    >
      <html lang={lang} className="h-full">
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          <link rel="preconnect" href="https://cdn.media.amplience.net/" />
          <link rel="preconnect" href="https://images.ctfassets.net/" />
          {/* <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: `
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/proxy/https%3A%2F%2Ffonts.gstatic.com%2Fs%2Fpoppins%2Fv20%2FpxiDyp8kv8JHgFVrJJLm21llEA.ttf)
    format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/proxy/https%3A%2F%2Ffonts.gstatic.com%2Fs%2Fpoppins%2Fv20%2FpxiGyp8kv8JHgFVrJJLedw.ttf)
    format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/proxy/https%3A%2F%2Ffonts.gstatic.com%2Fs%2Fpoppins%2Fv20%2FpxiDyp8kv8JHgFVrJJLmg1hlEA.ttf)
    format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/proxy/https%3A%2F%2Ffonts.gstatic.com%2Fs%2Fpoppins%2Fv20%2FpxiDyp8kv8JHgFVrJJLmr19lEA.ttf)
    format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/proxy/https%3A%2F%2Ffonts.gstatic.com%2Fs%2Fpoppins%2Fv20%2FpxiDyp8kv8JHgFVrJJLmy15lEA.ttf)
    format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/proxy/https%3A%2F%2Ffonts.gstatic.com%2Fs%2Fpoppins%2Fv20%2FpxiDyp8kv8JHgFVrJJLm111lEA.ttf)
    format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/proxy/https%3A%2F%2Ffonts.gstatic.com%2Fs%2Fpoppins%2Fv20%2FpxiByp8kv8JHgFVrLDz8V1s.ttf)
    format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/proxy/https%3A%2F%2Ffonts.gstatic.com%2Fs%2Fpoppins%2Fv20%2FpxiEyp8kv8JHgFVrFJA.ttf)
    format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/proxy/https%3A%2F%2Ffonts.gstatic.com%2Fs%2Fpoppins%2Fv20%2FpxiByp8kv8JHgFVrLGT9V1s.ttf)
  format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/proxy/https%3A%2F%2Ffonts.gstatic.com%2Fs%2Fpoppins%2Fv20%2FpxiByp8kv8JHgFVrLEj6V1s.ttf)
  format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/proxy/https%3A%2F%2Ffonts.gstatic.com%2Fs%2Fpoppins%2Fv20%2FpxiByp8kv8JHgFVrLCz7V1s.ttf)
  format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/proxy/https%3A%2F%2Ffonts.gstatic.com%2Fs%2Fpoppins%2Fv20%2FpxiByp8kv8JHgFVrLDD4V1s.ttf)
    format("truetype");
}
`,
}}
></style> */}
          <DeferSeo />
          <Meta />
          <ClientHintCheck nonce={""} />
          {/* All meta exports on all routes will go here */}
          {/* All link exports on all routes will go here */}
          <Links />
        </head>
        <body className="rendered relative min-h-full text-base leading-normal tracking-normal sm-max:pt-24 [&_iframe]:pointer-events-auto">
          <DeferH1Seo />
          <OnLocationChange />
          <AppRoot>
            <Outlet />
          </AppRoot>

          {/* Manages scroll position for client-side transitions */}
          {/* If you use a nonce-based content security policy for scripts, you must provide the `nonce` prop. Otherwise, omit the nonce prop as shown here. */}
          <ScrollRestoration />
          {/* Script tags go here */}
          {/* If you use a nonce-based content security policy for scripts, you must provide the `nonce` prop. Otherwise, omit the nonce prop as shown here. */}
          <Scripts />
          {/* Sets up automatic reload when you change code */}
          {/* and only does anything during development */}
          {/* If you use a nonce-based content security policy for scripts, you must provide the `nonce` prop. Otherwise, omit the nonce prop as shown here. */}
        </body>
      </html>
    </ContentfulLivePreviewProvider>
  );
}

export function AppRoot({ children }: { children: React.ReactNode }) {
  const { lang, site, locale, translations, APP_DEFAULT_LANG } =
    useLoaderData<typeof loader>();
  const buildUrl = createUrlTemplate(locale);
  return (
    <>
      <SiteProvider buildUrl={buildUrl} locale={locale} site={site}>
        <IntlProvider
          onError={err => {
            if (err.code === "MISSING_TRANSLATION") {
              /* NOTE: Remove the console error for missing translations during development, */
              /* as we knew translations would be added later. */
              // console.warn("Missing translation", err.message);
              return;
            }
            console.error(err);
            // throw err;
          }}
          locale={lang}
          // @ts-expect-error - serialize the translations causese a type error
          messages={translations}
          // For react-intl, the _default locale_ refers to the locale that the inline `defaultMessage`s are written for.
          // NOTE: if you update this value, please also update the following npm scripts in `package.json`:
          // - "extract-default-translations"
          // - "compile-translations:pseudo"
          defaultLocale={APP_DEFAULT_LANG}
        >
          {children}
          <Toaster />
          {/* Google Analytics 4 */}
          <ClientOnly>{() => <GA4 />}</ClientOnly>
        </IntlProvider>
      </SiteProvider>
    </>
  );
}

/**
 * This component is used to remove the no-transition class from the body when the location changes.
 * enabled css transitions
 */
const OnLocationChange = () => {
  const location = useLocation();
  const ref = useRef<ReturnType<typeof useLocation> | null>(location);
  useEffect(() => {
    // document.body.classList.add(noTransitionStyles["enable-transitions"]);
    ref.current = null;
  }, []);
  return null;
};
