import type React from "react";

interface DefaultImageProps {
  width?: number | string;
  height?: number | string;
}

const DefaultImage: React.FC<DefaultImageProps> = ({
  width = 24,
  height = 24,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      width={width}
      height={height}
      role="img"
      aria-label="Default product image placeholder"
    >
      <rect width="200" height="200" fill="#f4f4f4" />
      <path d="M50 150h100l-25-40-20 30-20-20z" fill="#dcdcdc" />
      <circle cx="100" cy="80" r="30" fill="#dcdcdc" />
      <text
        x="100"
        y="190"
        textAnchor="middle"
        fill="#aaa"
        fontFamily="Arial, sans-serif"
        fontSize="12"
      >
        Image not available
      </text>
    </svg>
  );
};

export default DefaultImage;
