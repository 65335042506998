import { useEffect } from "react";

import { useLocation } from "@remix-run/react";

import { useSite } from "~/contexts";
import { useLoadScript } from "~/lib/utils/load-script";

import * as gtag from "./utils/gtags.client";
import { usePageAnalytics } from "./utils/use-page-analytics";

export const GA4 = () => {
  const location = useLocation();
  const pageAnalytics = usePageAnalytics();
  // const lastLocationKey = useRef<string>(location.key);
  const { site } = useSite();
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag(...args: unknown[]) {
      window.dataLayer.push(args);
    }
    window.gtag = gtag;
    gtag("js", new Date());

    gtag("config", site.gaTrackingId, {
      page_path: window.location.pathname,
    });
    const timer = setInterval(() => {
      window.dataLayer = window.dataLayer.slice(-100);
    }, 60_000);
    return () => clearInterval(timer);
  }, []);
  useEffect(() => {
    if (pageAnalytics) {
      gtag.event(pageAnalytics);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  const loaded = useLoadScript(
    `https://www.googletagmanager.com/gtag/js?id=${site.gaTrackingId}`,
  );

  return (
    <>
      {/* <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${site.gaTrackingId}`}
      /> */}
      {/* <script
        async
        id="gtag-init"
        dangerouslySetInnerHTML={{
          __html: `
          console.log('gtag-init');
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${site.gaTrackingId}', {
            page_path: window.location.pathname,
          });
        `,
        }}
      /> */}
    </>
  );
};
